<template>
  <svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_600_10192)">
      <path d="M118.405 71.3719C118.998 70.3465 119.85 69.4952 120.877 68.9033C121.903 68.3114 123.066 67.9999 124.251 67.9999C125.436 67.9999 126.599 68.3114 127.626 68.9033C128.652 69.4952 129.504 70.3465 130.097 71.3719L185.614 167.527C186.205 168.553 186.516 169.717 186.516 170.901C186.516 172.085 186.204 173.248 185.612 174.274C185.021 175.3 184.17 176.152 183.145 176.745C182.12 177.338 180.957 177.651 179.773 177.653H68.75C67.5649 177.653 66.4008 177.341 65.3745 176.749C64.3481 176.157 63.4957 175.305 62.9028 174.279C62.3099 173.253 61.9973 172.089 61.9966 170.904C61.9958 169.719 62.3069 168.554 62.8985 167.527L118.405 71.3719Z" fill="url(#paint0_linear_600_10192)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M48 234C55.3299 219.02 66.7167 206.398 80.8652 197.57C95.0137 188.742 111.356 184.061 128.033 184.061C144.71 184.061 161.052 188.742 175.2 197.57C189.349 206.398 200.736 219.02 208.066 234H48Z" fill="url(#paint1_linear_600_10192)"/>
      <path d="M124.246 141.548C122.278 141.548 120.392 140.767 119 139.376C117.609 137.984 116.828 136.098 116.828 134.13V111.865C116.79 110.867 116.955 109.872 117.311 108.939C117.667 108.005 118.208 107.154 118.901 106.434C119.594 105.715 120.425 105.143 121.344 104.752C122.263 104.361 123.252 104.159 124.251 104.159C125.25 104.159 126.239 104.361 127.158 104.752C128.077 105.143 128.908 105.715 129.601 106.434C130.294 107.154 130.835 108.005 131.191 108.939C131.547 109.872 131.712 110.867 131.674 111.865V134.13C131.674 135.105 131.482 136.071 131.109 136.971C130.735 137.872 130.188 138.69 129.498 139.379C128.808 140.068 127.989 140.614 127.088 140.987C126.187 141.359 125.221 141.55 124.246 141.548Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M124.246 159.103C125.715 159.105 127.153 158.671 128.376 157.856C129.599 157.041 130.552 155.882 131.116 154.524C131.68 153.167 131.829 151.673 131.543 150.232C131.258 148.79 130.551 147.465 129.513 146.425C128.474 145.385 127.15 144.677 125.709 144.389C124.268 144.102 122.774 144.248 121.416 144.81C120.058 145.372 118.897 146.324 118.08 147.546C117.264 148.768 116.828 150.205 116.828 151.674C116.828 153.643 117.609 155.531 119 156.923C120.391 158.316 122.277 159.1 124.246 159.103Z" fill="white"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_600_10192" x1="106.992" y1="200.8" x2="169.263" y2="92.9311" gradientUnits="userSpaceOnUse">
        <stop :stop-color="secondaryColor"/>
        <stop offset="1" stop-color="white"/>
      </linearGradient>
      <linearGradient id="paint1_linear_600_10192" x1="128.033" y1="184.065" x2="128.033" y2="209.027" gradientUnits="userSpaceOnUse">
        <stop :stop-color="secondaryColor"/>
        <stop offset="0.76" :stop-color="secondaryColor" stop-opacity="0.03"/>
        <stop offset="0.89" :stop-color="secondaryColor" stop-opacity="0.01"/>
        <stop offset="1" :stop-color="secondaryColor" stop-opacity="0"/>
      </linearGradient>
      <clipPath id="clip0_600_10192">
        <rect width="160.066" height="166" fill="white" transform="translate(48 68)"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: 'Exclamation',
  props: {
    primaryColor: {
      type: String,
      default: window.document.body.style.getPropertyValue('--theme_primary')
    },
    secondaryColor: {
      type: String,
      default: window.document.body.style.getPropertyValue('--theme_secondary')
    }
  }
}
</script>

<style scoped>

</style>
