<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    :block="blockControl"
    :disabled="disabledControl"
    :height="height"
    :width="width"
    :class="theme"
    :loading="loading"
    class="default"
  >
    <slot></slot>
  </v-btn>
</template>
<script>
export default {
  name: 'wap-btn',
  props: {
    disabledControl: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 50
    },
    width: {
      type: [String, Number]
    },
    theme: {
      type: String,
      default: 'primary'
    },
    blockControl: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean
    }
  }
}
</script>

<style lang="sass" scoped>
.default
  border-radius: 8px!important
  box-shadow: 0 10.67px 21.33px 0 rgba(197, 200, 214, 0.40), 0 2.67px 5.33px 0 rgba(133, 144, 165, 0.20)!important
  min-width: 50px!important

.primary
  color: #FFFFFF
  background: linear-gradient(to right, var(--theme_primary), var(--theme_third), var(--theme_secondary))
  font-weight: bold

.normal
  color: #FFFFFF
  background: var(--theme_primary)!important
  font-weight: bold

.simple
  border-radius: 8px!important
  box-shadow: none
  min-width: 50px!important

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg
  color: var(--theme_primary_opacity_low) !important
  background: var(--theme_primary_opacity_high) !important
  font-weight: bold
</style>
